import { Button } from 'components/Button';
import { FieldErrors } from 'react-hook-form';
import { FormEventHandler, ReactNode, useMemo } from 'react';
import { Loader } from 'components/Loader';
import styles from './Form.module.css';

interface Properties {
  buttonLabel?: string;
  children?: ReactNode;
  error?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldErrors?: FieldErrors<any>;
  isLoading?: boolean;
  onSubmit: FormEventHandler<HTMLFormElement>;
}

export const Form = ({ buttonLabel = 'Versturen', children, error, fieldErrors, isLoading, onSubmit }: Properties) => {
  const hasFieldErrors = useMemo(() => (fieldErrors ? Object.values(fieldErrors).length > 0 : false), [fieldErrors]);

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      {children}
      <div className={styles.buttonContainer}>
        <Button disabled={isLoading} type="submit">
          {buttonLabel}
        </Button>
        {!!isLoading && <Loader />}
      </div>
      {!!error && <div className={styles.error}>{error}</div>}
      {!!hasFieldErrors && <div className={styles.error}>Niet alle invoer is juist, controleer de velden.</div>}
    </form>
  );
};
