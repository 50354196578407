import { ForgotPasswordForm } from 'components/forms/ForgotPasswordForm';
import { LoginForm } from 'components/forms/LoginForm';
import { MaxWidth } from 'components/MaxWidth';
import { RichText } from 'components/RichText';
import { Fragment, useCallback, useState } from 'react';
import styles from './LoginFormContainer.module.css';

export const LoginFormContainer = () => {
  const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);

  const toggleShowForgotPasswordForm = useCallback((showForgotPasswordFormState: boolean) => {
    setShowForgotPasswordForm(showForgotPasswordFormState);
  }, []);

  return (
    <MaxWidth size="small">
      {showForgotPasswordForm ? (
        <Fragment>
          <div className={styles.introContainer}>
            <RichText>
              <h1>Reset je wachtwoord</h1>
              <p>Vul je e-mailadres in om een resetlink te ontvangen waarmee je je wachtwoord opnieuw kunt instellen</p>
            </RichText>
          </div>
          <ForgotPasswordForm toggleShowForgotPasswordForm={toggleShowForgotPasswordForm} />
        </Fragment>
      ) : (
        <Fragment>
          <div className={styles.introContainer}>
            <RichText>
              <h1>Om deze pagina te bekijken moet je ingelogd zijn.</h1>
              <p>Log in met je e-mailadres en wachtwoord om toegang te krijgen tot FlexDirect.</p>
            </RichText>
          </div>
          <LoginForm toggleShowForgotPasswordForm={toggleShowForgotPasswordForm} />
        </Fragment>
      )}
    </MaxWidth>
  );
};
