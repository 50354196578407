import { Loader } from 'components/Loader';
import { LoginFormContainer } from 'components/LoginFormContainer';
import { ReactNode, useMemo } from 'react';
import { Sidebar } from 'components/Sidebar';
import { useUser } from 'providers/UserProvider';
import Head from 'next/head';
import styles from './Page.module.css';
import { useRouter } from 'next/router';

const defaults = {
  description: 'Met FlexDirect plan je al je medewerkers op het juiste moment op de juiste plek',
  title: 'FlexDirect',
};

interface Properties {
  children?: ReactNode;
  description?: string;
  doesNotNeedUser?: boolean;
  title?: string;
}

export const Page = ({ children, description, title }: Properties) => {
  const router = useRouter();
  const { isLoadingUser, user } = useUser();
  const shouldShowLoader = useMemo(() => isLoadingUser && !user, [isLoadingUser, user]);
  const shouldShowLogin = useMemo(
    () => !shouldShowLoader && !user && !router.asPath.includes('wachtwoord-reset'),
    [router.asPath, shouldShowLoader, user]
  );

  const titleToUse = useMemo(() => (title ? `${title} | FlexDirect` : defaults.title), [title]);

  return (
    <div className={styles.container}>
      <Head>
        <title>{titleToUse}</title>
        <meta name="title" content={titleToUse} />
        <meta property="og:title" content={titleToUse} />
        <meta name="description" content={description || defaults.description} />
        <meta property="og:description" content={description || defaults.description} />

        <meta property="og:type" content="website" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:creator" content="@LinkUimedia" />
        <meta property="twitter:title" content={titleToUse} />
        <meta property="twitter:description" content={description || defaults.description} />
      </Head>
      <Sidebar />
      <main className={styles.main}>
        {shouldShowLoader ? <Loader /> : shouldShowLogin ? <LoginFormContainer /> : children}
      </main>
    </div>
  );
};
