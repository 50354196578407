import { ReactNode, useMemo } from 'react';
import styles from './MaxWidth.module.css';

interface Properties {
  children: ReactNode;
  size?: 'small' | 'medium';
}

export const MaxWidth = ({ children, size }: Properties): JSX.Element => {
  const className = useMemo(
    () =>
      [styles.container, size === 'small' ? styles.small : undefined, size === 'medium' ? styles.medium : undefined]
        .filter(Boolean)
        .join(' '),
    [size]
  );

  return <div className={className}>{children}</div>;
};
