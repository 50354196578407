import { Icon, IconType } from 'components/Icon';
import Link from 'next/link';
import React, { AnchorHTMLAttributes, ButtonHTMLAttributes, MouseEventHandler, ReactNode, useMemo } from 'react';
import styles from './Button.module.css';

export interface ButtonProperties extends ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode;
  color?: 'light' | 'dark' | 'green' | 'bright' | 'transparent' | 'red' | 'bright';
  outLine?: boolean;
  iconAfter?: IconType;
  iconBefore?: IconType;
  href?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  small?: boolean;
}

export const Button = ({
  children,
  color,
  href,
  iconAfter,
  iconBefore,
  outLine,
  onClick,
  small,
  type = 'button',
  ...rest
}: ButtonProperties) => {
  const className = useMemo(
    () =>
      [
        styles.button,
        color === 'light' ? styles.lichtBlauw : undefined,
        color === 'dark' ? styles.donkerblauw : undefined,
        color === 'green' ? styles.groen : undefined,
        color === 'red' ? styles.rood : undefined,
        color === 'bright' ? styles.bright : undefined,
        color === 'transparent' ? styles.transparent : undefined,
        outLine ? styles.outLine : undefined,
        small ? styles.small : undefined,
      ]
        .filter(Boolean)
        .join(' '),
    [color, outLine, small]
  );

  const buttonInner = useMemo(
    () => (
      <span className={styles.buttonInner}>
        {!!iconBefore && <Icon type={iconBefore} />}
        <span>{children}</span>
        {!!iconAfter && <Icon type={iconAfter} />}
      </span>
    ),
    [children, iconAfter, iconBefore]
  );

  if (href) {
    return (
      <Link href={href} className={className} {...(rest as AnchorHTMLAttributes<HTMLAnchorElement>)}>
        {buttonInner}
      </Link>
    );
  }

  return (
    <button
      className={className}
      onClick={onClick}
      type={type === 'submit' ? 'submit' : type === 'reset' ? 'reset' : 'button'}
      {...rest}>
      {buttonInner}
    </button>
  );
};
