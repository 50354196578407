import { ReactNode, useMemo } from 'react';
import styles from './RichText.module.css';

interface Properties {
  center?: boolean;
  children: ReactNode;
}

export const RichText = ({ center, children }: Properties) => {
  const classNames = useMemo(() => (center ? `${styles.container} ${styles.center}` : styles.container), [center]);

  return <div className={classNames}>{children}</div>;
};
