import { ApiError } from 'helpers/api';
import { captureException } from 'helpers/captureException';

export const DEFAULT_MESSAGE = 'Er ging iets mis, probeer het nog eens.';

export function handleFormError(error: unknown, onError: (message: string) => void) {
  if (error instanceof ApiError) {
    let errorBody = error.body;

    try {
      // TODO: remove this when PR is merged in the generator
      errorBody = typeof error.body === 'string' ? JSON.parse(error.body) : error.body;
    } catch {
      // Do nothing
    }

    if (error.status === 500) {
      captureException(error);
      onError(DEFAULT_MESSAGE);
    } else if (error.status === 401) {
      onError(errorBody.message || errorBody.detail || DEFAULT_MESSAGE);
    } else {
      captureException(error);
      onError(errorBody.message || errorBody.detail || DEFAULT_MESSAGE);
    }
  } else {
    onError(DEFAULT_MESSAGE);
    captureException(error);
  }
}
