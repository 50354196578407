import { Form } from 'components/Form';
import { handleFormError } from 'helpers/handleFormError';
import { Input } from 'components/Input';
import { useCallback, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Loader } from 'components/Loader';
import { requestResetPassword } from 'helpers/api';
import styles from './LoginForm.module.css';

interface Inputs {
  email: string;
}

interface ForgotPasswordFormProperties {
  toggleShowForgotPasswordForm: (showForgotPasswordFormState: boolean) => void;
}

export const ForgotPasswordForm = ({ toggleShowForgotPasswordForm }: ForgotPasswordFormProperties) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>();
  const [error, setError] = useState<string>();

  const onSubmit: SubmitHandler<Inputs> = useCallback(async (data) => {
    try {
      setError(undefined);
      await requestResetPassword(data.email);
      alert('Er is een mail verstuurd met een resetlink!');
    } catch (_error) {
      handleFormError(_error, setError);
    }
  }, []);

  const ontoggleShowForgotPasswordForm = useCallback(() => {
    toggleShowForgotPasswordForm(false);
  }, [toggleShowForgotPasswordForm]);

  return (
    <Form error={error} onSubmit={handleSubmit(onSubmit)}>
      <Input
        error={errors.email?.message}
        label="E-mailadres"
        type="email"
        required
        {...register('email', { required: 'Dit veld is verplicht' })}
      />
      {!!isSubmitting && <Loader />}
      <button type="button" className={styles.forgotPassword} onClick={ontoggleShowForgotPasswordForm}>
        Terug naar login
      </button>
    </Form>
  );
};
