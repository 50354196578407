import { ReactNode } from 'react';
import styles from './InputContainer.module.css';

export interface InputContainerProperties {
  children: ReactNode;
  className?: string;
  description?: string;
  error?: string;
  label?: string;
  required?: boolean;
}

export const InputContainer = ({
  children,
  className,
  description,
  error,
  label,
  required,
}: InputContainerProperties) => (
  <div>
    <div className={styles.labelContainer}>
      {!!label && <div>{label}</div>}
      {!required && <div className={styles.optional}>Optioneel</div>}
    </div>
    {!!description && <div className={styles.description}>{description}</div>}
    <div className={className}>{children}</div>
    {!!error && <div className={styles.error}>{error}</div>}
  </div>
);
