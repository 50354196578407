import { Form } from 'components/Form';
import { handleFormError } from 'helpers/handleFormError';
import { Input } from 'components/Input';
import { useCallback, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useUser } from 'providers/UserProvider';
import styles from './LoginForm.module.css';

interface Inputs {
  email: string;
  password: string;
}

export const LoginForm = ({
  toggleShowForgotPasswordForm,
}: {
  toggleShowForgotPasswordForm: (showForgotPasswordFormState: boolean) => void;
}) => {
  const { login } = useUser();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Inputs>();
  const [error, setError] = useState<string>();

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    async (data) => {
      try {
        setError(undefined);
        await login(data);
      } catch (_error) {
        handleFormError(_error, setError);
      }
    },
    [login]
  );

  const ontoggleShowForgotPasswordForm = useCallback(() => {
    toggleShowForgotPasswordForm(true);
  }, [toggleShowForgotPasswordForm]);

  return (
    <Form
      buttonLabel="Inloggen"
      error={error}
      fieldErrors={errors}
      isLoading={isSubmitting}
      onSubmit={handleSubmit(onSubmit)}>
      <Input
        error={errors.email?.message}
        label="E-mailadres"
        type="email"
        required
        {...register('email', { required: 'Dit veld is verplicht' })}
      />
      <Input
        error={errors.password?.message}
        label="Wachtwoord"
        type="password"
        required
        {...register('password', { required: 'Dit veld is verplicht' })}
      />
      <button type="button" className={styles.forgotPassword} onClick={ontoggleShowForgotPasswordForm}>
        Wachtwoord vergeten?
      </button>
    </Form>
  );
};
