import { Icon, IconType } from 'components/Icon';
import { useRouter } from 'next/router';
import { useUser } from 'providers/UserProvider';
import Link from 'next/link';
import React, { useMemo } from 'react';
import styles from './Sidebar.module.css';

interface NavigationLinkProperties {
  href: string;
  icon: IconType;
  label: string;
}

const NAVIGATION_ITEMS: NavigationLinkProperties[] = [
  { label: 'Dashboard', href: '/', icon: 'chart' },
  { label: 'Medewerkers', href: '/medewerkers', icon: 'users' },
  { label: 'Woningen', href: '/woningen', icon: 'house' },
  { label: 'Vervoer', href: '/vervoer/autos', icon: 'carSideBar' },
  { label: 'Accountbeheer', href: '/accountbeheer', icon: 'user' },
];

export const Sidebar = () => {
  const { logout, user } = useUser();

  return (
    <aside className={styles.container}>
      <div className={styles.containerBackground} />
      <Link href="/" className={styles.logo}>
        Flex<span>Direct</span>
      </Link>

      {!!user && (
        <React.Fragment>
          <nav className={styles.navigation}>
            <ul>
              {NAVIGATION_ITEMS.map(({ label, href, icon }) => (
                <li key={href}>
                  <NavigationLink label={label} href={href} icon={icon} />
                </li>
              ))}
            </ul>
          </nav>

          <button className={styles.navigationLink} onClick={logout} type="button">
            <Icon type="logout" />
            <span>Uitloggen</span>
          </button>
        </React.Fragment>
      )}
    </aside>
  );
};

const NavigationLink = ({ href, icon, label }: NavigationLinkProperties) => {
  const { asPath } = useRouter();

  const className = useMemo(() => {
    const isActive =
      asPath === '/'
        ? href === '/'
        : asPath.includes('vervoer')
          ? href.includes('vervoer')
          : asPath.includes(href) && href !== '/';
    return isActive ? `${styles.navigationLink} ${styles.active}` : styles.navigationLink;
  }, [asPath, href]);

  return (
    <Link href={href} className={className}>
      <Icon type={icon} />
      <span>{label}</span>
    </Link>
  );
};
