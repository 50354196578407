import { InputContainer, InputContainerProperties } from 'components/InputContainer';
import React, { forwardRef, InputHTMLAttributes, useMemo } from 'react';
import styles from './Input.module.css';

interface InputProperties extends Omit<InputContainerProperties, 'children'>, InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type?: 'date' | 'email' | 'number' | 'password' | 'tel' | 'text' | 'file' | 'hidden';
}

export const Input = forwardRef<HTMLInputElement, InputProperties>(
  ({ description, error, label, required, type = 'text', name, ...rest }, reference) => {
    const classNames = useMemo(() => (error ? `${styles.input} ${styles.error}` : styles.input), [error]);

    return (
      <InputContainer description={description} error={error} label={label} required={required}>
        <input className={classNames} type={type} ref={reference} name={name} {...rest} />
      </InputContainer>
    );
  }
);

Input.displayName = 'Input';

interface TextareaProperties
  extends Omit<InputContainerProperties, 'children'>,
    InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextareaProperties>(
  ({ description, error, label, required, name, ...rest }, reference) => {
    const classNames = useMemo(() => (error ? `${styles.input} ${styles.error}` : styles.input), [error]);

    return (
      <InputContainer description={description} error={error} label={label} required={required}>
        <textarea rows={5} className={classNames} ref={reference} name={name} {...rest} />
      </InputContainer>
    );
  }
);

TextArea.displayName = 'TextArea';
